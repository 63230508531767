<template>
	<div class="grid">
		<div class="col-12">
            <button class="btn btn-voltar" @click="voltarPagina()"><img src="/images/icones/seta_voltar.png"><div class="conteudo_btn_voltar">Voltar</div></button>
            <div class="div_titulo_pagina">
                <tr>
                    <td style="display:inline"><a href="/anuncios"><span class="titulo_pagina">Anúncios<img id="img_sinal" src="/images/icones/sinal.png"></span></a></td>
                    <td style="display:inline"><span style="color:#488EFF; font-size:14pt;">Anúncio</span></td>
                </tr>
                <span></span>
            </div>
			<div class="card">
                <div>
                    <h5 class="titulo_pedidos">Anuncio</h5>
                    <div class="botoes_topo" v-if="this.modo=='visualizar'">
                        <Button @click="modoEditarAnuncio()" label="Editar" class="p-button-primary"/>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-8">
                        <h1>{{this.anuncio.nome}}</h1>
                    </div>
                </div>
                <Loading v-if="loading"/>
                <div v-if="!loading">
                    <TabView>
                        <TabPanel header="Dados Gerais">
                            <div class="grid">
                                <div class="col-4">
                                    <label>SKU</label>
                                    <InputText v-model="this.anuncio.sku" v-if="this.modo=='editar'"/>
                                    <p v-if="this.modo=='visualizar'">{{this.anuncio.sku}}</p>
                                </div>
                                <div class="col-4">
                                    <label>Status</label>
                                    <Dropdown v-if="this.modo=='editar'" v-model="this.anuncio.status" :options="this.status" optionLabel="name" optionValue="code" placeholder="Selecione o status"  style="width:100%"/>
                                    <p v-if="this.modo=='visualizar'">{{this.anuncio.status}}</p>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-12">
                                    <label>Título</label>
                                    <p v-if="this.modo=='visualizar'">{{this.anuncio.nome}}</p>
                                    <InputText v-model="this.anuncio.nome" v-if="this.modo=='editar'"/>
                                </div>
                            </div>
                            <h1>Dimensões</h1>
                            <div class="grid">
                                <div class="col-3">
                                    <label>Preço de Venda</label>
                                    <p v-if="this.modo=='visualizar'">R$ {{anuncio.preco_normal_formatado}}</p>
                                    <div class="p-inputgroup" v-if="this.modo=='editar'" >
                                        <span class="p-inputgroup-addon">R$</span>
                                        <InputText v-model="anuncio.preco_normal" placeholder="0,00" type="number" />
                                    </div>
                                </div>  

                                <div class="col-3">
                                    <label>Preço Promocional</label>
                                    <p v-if="this.modo=='visualizar'">R$ {{anuncio.preco_desconto_formatado}}</p>
                                    <div class="p-inputgroup" v-if="this.modo=='editar'" >
                                        <span class="p-inputgroup-addon">R$</span>
                                        <InputText v-model="anuncio.preco_desconto" placeholder="0,00" type="number" />
                                    </div>
                                </div>  
                            </div>

                            <h1>Dimensões</h1>
                            <div class="grid">
                                <div class="col-3">
                                    <label>Peso</label>
                                    <p v-if="this.modo=='visualizar'">{{anuncio.altura}}</p>
                                    <div class="p-inputgroup"  v-if="this.modo=='editar'">
                                        <InputText v-model="anuncio.peso" placeholder="Em cm" type="number" />
                                        <span class="p-inputgroup-addon">cm</span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid">
                                <div class="col-3">
                                    <label>Altura</label>
                                    <p v-if="this.modo=='visualizar'">{{anuncio.altura}} cm</p>
                                    <div class="p-inputgroup"  v-if="this.modo=='editar'">
                                        <InputText v-model="anuncio.altura" placeholder="Em cm" type="number" />
                                        <span class="p-inputgroup-addon">cm</span>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <label>Largura</label>
                                    <p v-if="this.modo=='visualizar'">{{anuncio.largura}} cm</p>   
                                    <div class="p-inputgroup" v-if="this.modo=='editar'">
                                        <InputText v-model="anuncio.largura" placeholder="Em cm" type="number" />
                                        <span class="p-inputgroup-addon">cm</span>
                                    </div>                      
                                </div>

                                <div class="col-3">
                                    <label>Profundidade</label>
                                    <p v-if="this.modo=='visualizar'">{{anuncio.profundidade}} cm</p>     
                                    <div class="p-inputgroup" v-if="this.modo=='editar'">
                                        <InputText v-model="anuncio.profundidade" placeholder="Em cm" type="number" />
                                        <span class="p-inputgroup-addon">cm</span>
                                    </div>
                                </div>
                            </div>
                            <h1>Descrição</h1>
                            <div class="grid">
                                <div class="col-12">
                                    <div style="width: 80%" class="inputContainer">
                                        <div v-if="editor">
                                            <button class="btn_editor" title="Negrito" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                                            <i class="bi bi-type-bold"></i>
                                            </button>
                                            <button class="btn_editor" title="Itálico" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                                            <i class="bi bi-type-italic"></i>
                                            </button>
                                            <button class="btn_editor" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                                            <i class="bi bi-type-h1"></i>
                                            </button>
                                            <button class="btn_editor" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                                            <i class="bi bi-type-h2"></i>
                                            </button>
                                            <button  class="btn_editor" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                                            <i class="bi bi-type-h3"></i>
                                            </button>
                                            <button class="btn_editor" title="Lista" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                                            <i class="bi bi-list-task"></i>
                                            </button>
                                            <button class="btn_editor" title="Numeração" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                                            <i class="bi bi-list-ol"></i>
                                            </button>
                                            <button class="btn_editor" title="Linha Horizontal" @click="editor.chain().focus().setHorizontalRule().run()">
                                                <i class="bi bi-dash"></i>        
                                            </button>
                                            <button class="btn_editor" title="Alinhamento a Esquerda" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                                            <i class="bi bi-justify-left"></i>
                                            </button>
                                            <button class="btn_editor" title="Alinhamento ao Centro" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                                            <i class="bi bi-text-center"></i>
                                            </button>
                                            <button class="btn_editor" title="Alinhamento a Direita" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                                            <i class="bi bi-justify-right"></i>
                                            </button>
                                            <button class="btn_editor" title="Justificado" @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                                            <i class="bi bi-justify"></i>
                                            </button>
                                            <button class="btn_editor" title="Voltar" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                                            <i class="bi bi-arrow-90deg-left"></i>
                                            </button>
                                            <button class="btn_editor" title="Avançar" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
                                                <i class="bi bi-arrow-90deg-right"></i>
                                            </button>
                                        </div>
                                        <editor-content :editor="editor" />
                                        <label>Caso não informada, será utilizada a descrição do cadastro de produto</label>
                                    </div>
                                </div>
                            </div>
                            <h1>Estoque</h1>
                            <div class="grid">
                                <div class="col-3">
                                    <label>Estoque</label>
                                    <p v-if="this.modo=='visualizar'">{{anuncio.estoque}}</p>
                                    <div class="p-inputgroup"  v-if="this.modo=='editar'">
                                        <InputText v-model="anuncio.estoque" placeholder="Em cm" type="number" />
                                    </div>
                                </div>
                            </div>
                            <h1>Imagens</h1>
                            <div class="grid">
                                <div class="col-4" v-if="this.modo == 'editar'">
                                    <div class="inputFile" style="margin-top:10px; height:33px !important">
                                        <div style="position:absolute; left: 50%; margin-left: -60px; margin-top: 7px">
                                            Selecionar Arquivo
                                        </div>
                                        <div style="border: #000 solid 1px">
                                            <input type="file" style="width:100%" @change="adicionarImagens" multiple>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1" v-for="(imagem, index) in this.state.imagens" :key="index" >
                                    <img :src="imagem.url" class="imagem" />
                                    <button class="btn_deletar_imagem">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Atributos">
                            <table class="p-datatable-table" style="width:100%" role="rowgroup" v-if="this.modo=='visualizar'">
                                <thead class="p-datatable-thead" role="rowgroup">
                                    <Button label="Adicionar Anúncio" class="p-button-success" @click="displayAnuncio = true" v-if="this.modo=='editar'" />                            
                                    <tr role="row">
                                        <th style="text-align: start;" :style="'width:'+nomeColuna['width']" v-for="(nomeColuna, index) in nomeColunasAtributo" :key="index" class="p-sortable-column" tabindex="0" role="cell">
                                            <div class="p-column-header-content">
                                                <span v-html="nomeColuna['nome']" class="p-column-title"></span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="p-datatable-tbody" role="rowgroup" >
                                    <tr v-for="(anuncio, index) in anuncio.atributos" :key="index" class="" role="row">
                                        <td style="text-align: start;padding: 3px 10px; border-bottom: #00000050 1px solid;" v-for="(nomeColuna, index) in nomeColunasAtributo" :key="index" class=""  role="cell">{{ anuncio[nomeColuna.value] }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div v-if="this.modo=='editar'">
                                <div v-for="n in this.qtd_atributos" :key="n" >
                                    <div class="flex inputGroup" :id="'div_editar_atributo_'+id_atributo[n]">
                                        <div style="width: 40%" class="inputContainer">
                                            <label>Atributo</label>
                                            <InputText placeholder="" v-model="nome_atributo[n]" />

                                        </div>
                                        <div class="inputContainer valor_atributos">
                                            <label>Valor</label>
                                            <InputText placeholder="" v-model="valor_atributo[n]"/>

                                        </div>
                                        <div class="botoes_atributos">
                                            <button class="btn_transparente" title="Salvar Atributo" @click="salvarAtributo(nome_atributo[n], valor_atributo[n])" ><img class="icone_atributo_salvar" src="/images/icones/btn_ok.png"/> </button>
                                            <button class="btn_transparente" title="Remover Atributo" @click="deletarAtributo(id_atributo[n])" ><img class="icone_atributo_deletar" src="/images/icones/btn_excluir.png" /> </button>
                                        </div>
                                    </div>
                                </div>
                                <Button @click="adicionarAtributo()" class="btn_add_atributos"  label="Adicionar Atributo"/>
                            </div>
                        </TabPanel>                 
                    </TabView>
                </div>
                <div v-if="modo=='editar'">
                    <div class="div_botoes">
                        <Button label="Salvar" class="p-button-primary btn_success"  @click="salvarAnuncio()" />
                        <Button label="Cancelar" class="p-button-primary btn_cancel"  @click="voltarAnuncios()"/>
                    </div>
                </div>
			</div>
		</div>
	</div>
    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import { reactive } from 'vue';

export default {

    setup() {
        const state = reactive({
            imagens: [],
        })  
        return {
            state
        }
    },

    data() {
        
        return {
            empresa_id:0,
            api: "",
            loading: true,
            modo:"editar",//visualizar ou editar
            anuncio: {
                id:0,
            },
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            nome_marketplace:"",
            id_anuncio:0,
            id_integracao:0,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            qtd_atributos:1,
            totalLinhas: null,
            quantidades: ["10", "20", "50"],
            status: [
                { name:'Ativo', code:'ATIVO' },
                { name: 'Inativo', code: 'INATIVO' }
            ],
            nomeColunas: [
                { nome: "Nome", value: "nome" },
                { nome: "Descrição", value: "descricao" },
                { nome: "sku", value: "sku" },
                { nome: "ean", value: "ean" },
                { nome: "Preço <br/>Normal", value: "preco_normal" },
                { nome: "Preço com <br/>Desconto", value: "preco_desconto" },
                { nome: "Altura", value: "altura" },
                { nome: "Largura", value: "largura" },
                { nome: "Profundidade", value: "profundidade" },
                { nome: "Peso", value: "peso" },
                { nome: "Estoque", value: "estoque" },
                { nome: "Video", value: "video" },
            ],
            nomeColunasAtributo: [
                { nome: "Atributo", value: "nome_atributo", width:"50%" },
                { nome: "Valor", value: "valor_atributo", width:"50%" }
            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { ean: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
                { status: ""}
            ],
            mostrarAdicionar: false,
            id_atributo:[],
            nome_atributo:[],
            valor_atributo:[],
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.id_integracao = this.$router.currentRoute.value.params.integracao
        //        console.log("Integracao: "+this.id_integracao)

        this.empresa_id = this.empresa.id
        this.api = new Api();
      //  this.api.qtdAnuncios().then(data => { this.totalLinhas = data.data.quantidade })
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
            ],
            onBlur: ({ editor }) => {
                this.anuncio.descricao = editor.getHTML();

            }
        })


        this.carregarIntegracao();
        if(this.$router.currentRoute.value.params.id != undefined){
            this.anuncio.id = this.$router.currentRoute.value.params.id
            this.carregarAnuncio();
        }else{
            this.anuncio.id = 0
            this.anuncio.integracao_id = this.$router.currentRoute.value.params.integracao
            this.modo = 'editar'
        }
        this.loading = false;
    },
    methods: {
        voltarPagina(){
            this.$router.go(-1)
        },
        carregarIntegracao(){
            this.api.carregarIntegracao(this.token, this.empresa_id, this.$router.currentRoute.value.params.integracao).then(data =>{
                this.nome_marketplace = data.data.nome_marketplace
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        carregarAnuncio() {
            this.loading = true;
            this.api.carregarAnuncio(this.token,this.empresa_id,this.$router.currentRoute.value.params.id).then(data => {
                this.anuncio = data.data.resposta
                
                this.state.imagens = this.anuncio.imagens
            //    this.state.imagens = this.produto.imagens

                this.qtd_atributos = data.data.resposta.atributos.length                
                for (let i = 0; i < this.qtd_atributos; i++) {
                    const element = data.data.resposta.atributos[i];
                    this.id_atributo[i+1] = element.id
                    this.nome_atributo[i+1] = element.nome_atributo
                    this.valor_atributo[i+1] = element.valor_atributo
                }
                this.editor.commands.setContent(this.anuncio.descricao)

                this.loading = false;
			}).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        modoEditarAnuncio(){
            this.modo = "editar"
        },
        modoVisualizarAnuncio(){
            this.carregarAnuncio();
            this.modo = "visualizar"
        },

        voltarAnuncios() {
            this.$router.push({ name: 'anunciosMarketplace', params: { id_marketplace: this.anuncio.integracao_id, nome_marketplace: this.nome_marketplace } })
        },
        adicionarAtributo(){
            this.qtd_atributos = this.qtd_atributos + 1;
        },
        
        salvarAtributo(nome_atributo,valor_atributo){
            this.api.salvarAtributoAnuncio(this.token, this.empresa_id,this.anuncio.id,nome_atributo, valor_atributo).then(data =>{
                console.log(data)
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        deletarAtributo(atributo_id){
            if(!confirm('Deseja deletar o Atributo?')){
                document.querySelector('#div_editar_atributo_'+atributo_id).remove();
                //this.produto.atributos.splice(index,1)
                return;
            }
            this.api.deletarAtributoPedido(this.empresa_id,atributo_id).then(data=>{
                if(data.data.erro == false){
                    document.querySelector('#div_editar_atributo_'+atributo_id).remove();
                }else{
                    alert('Erro ao deletar Atributo');
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        }, 
        async salvarAnuncio(){
            if(this.anuncio.id != 0){  
                await this.api.updateAnuncio(this.token,this.anuncio,this.empresa_id).then(data =>{
                    this.modo = 'visualizar';
                    this.carregarAnuncio();
                    console.log(data)
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }else{
                await this.api.saveAnuncio(this.token,this.anuncio,this.empresa_id).then(data =>{
                    this.$router.push({ name: 'anunciosMarketplace', params: { id_marketplace: this.id_integracao, nome_marketplace: this.nome_marketplace } })
                    console.log(data)
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }

        },
        setQuantidadePorPagina(quantidade) {
            this.quantidadePorPagina = quantidade;
            this.listarAnuncios();
        },
        paginaAtual(pagina) {
            this.pagina = pagina;
            // this.primeiroItemDaPagina = pagina * this.quantidadePorPagina + 1 - this.quantidadePorPagina;
            this.listarAnuncios();
        },
        proximaPagina() {
            if (this.quantidadePaginas > this.pagina)
                this.paginaAtual(this.pagina + 1);
                this.listarAnuncios();
        },
        anteriorPagina() {
            if (this.pagina > 1) {
                this.paginaAtual(this.pagina - 1);
                this.listarAnuncios();
            }
        },
        primeiraPagina() {
            this.paginaAtual(1);
            this.listarAnuncios();
        },
        ultimaPagina() {
            this.paginaAtual(this.quantidadePaginas);
            this.listarAnuncios();
        },
        adicionarAnuncio() {
            this.$toast.add({ severity: "success", summary: "Sucesso", detail: "Produto Adicionado", life: 3000 });
            this.limparFormAnuncio();
        },
        limparFormAnuncio() {
            for (let field in this.produtosAdicionar) {
                this.anunciosAdicionar[field] = "";
            }
            this.mostrarAdicionar = false;
        }
    },
    components: { Loading, EditorContent }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
.btn_deletar_imagem{
    background-color: transparent;
    border:none;
    float:right;
    color:red;
    cursor: pointer;
}
.imagem{
    width:70%;
    float: left;
}
.inputFile{
    width: 100%;
    height: 60px;
    position:relative;
    overflow: hidden;
    border-bottom: #000 solid 1px;
}

.inputFile input{
    opacity: 0;
    filter: alpha(opacity=0);
    right:0;
    padding-top: 5px;
    z-index: 2;
    height: 80px;
    font-size:100px;
}

input{
    margin: 10px;
}
.marketplace{
    width:33%;
    display: inline-block;
    padding:15px;
}

.div_botoes{
    margin-bottom:10px;
    width:100%;
    text-align:center;
}
.btn_cancel{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
    margin-left:10px;
    width: 15rem;
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
}
.btn_success{
    width: 15rem;
}
.card_marketplace{
    width:100%;
    padding:14px;

    border-radius: 5px;
    border: #000 solid 1px;
}

.titulo_marketplace{
    font-size: 14pt;
    font-weight: bold;
}

.informacoes_anuncios .numero{
    font-size: 20pt;
    font-weight: bold;
    margin-bottom:0;
    margin-top:10px;
}
.informacoes_anuncios .titulo_informacoes{
    font-size: 12pt;
    font-weight: bold;
}
input{
    margin: 0px;
    width:100%
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.titulo_pedidos{
    display: inline-block; 
    width: 50%;
}
label{
    font-size: 12pt;
    font-weight: bold;
}
th{
    text-align: start;
    padding: 5px 10px; 
    font-weight:normal; 
    border: #ebeef3 solid 1px
}

.btn_add_atributos{
    margin-top:10px;
    padding:6px 22px;
    background-color:#8EC253;
    border: none;
}
.btn_add_atributos:hover{
    background-color:#8EC253;
    border: none;
}
.botoes_atributos{
    margin-top:20px;
}
.btn_transparente{
    padding:0;
    background:none;
    border:none;
}
.btn_transparente:hover{
    cursor:pointer;
}
.icone_atributo_salvar{
    width:25px;
    margin-left:10px;
}
.icone_atributo_deletar{
    margin-left:8px;
}
.valor_atributos{
    width: 40%;
    margin-left:10px;
}
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
th{
    padding:5px;
    text-align: start;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
h1{
    font-size:16pt;
}
textarea{
    width:100%;
    resize: none;
    height:100pt;
    border-radius:6px;
}

.conteudo_btn_voltar{
    color:#488EFF;
    font-size:14pt;
    float: left;
    margin-left:5px;
    margin-top:-2px;
}
.btn-voltar img{
    float: left;
    margin-top:3px;
}
#img_sinal{
    margin-left:6px;
    margin-right:6px;
}
.div_titulo_pagina{
    margin-top:0.6rem;
    margin-bottom:1rem;
}
.titulo_pagina{
    color:#000;
    font-size:18pt;
    font-weight:600;
}

.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.btn-voltar{
    cursor: pointer;
    background-color:#fff;

    border:none;

    padding: 8px 12px;
    border-radius: 25px;
}
.btn_editor{
    background:none;
    border: #DDDDDD solid 1px;
    padding:6px;
    margin-right: 5px;
    border-radius: 5px;
    color:#394D6F;
    font-weight: bold;
}
.btn_editor :hover{
    cursor: pointer;
}
</style>

<style lang="scss">
/* Basic editor styles */

.ProseMirror {
    margin-top:10px;
    border: #ccc solid 1px;
    border-radius: 3px;
    min-height: 300px;

    >*+* {
        margin-top: 0.75em;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 2px solid rgba(#0D0D0D, 0.1);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(#0D0D0D, 0.1);
        margin: 2rem 0;
    }
}
</style>